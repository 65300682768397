import React from "react";
import AuthLayout from "../../components/authLayout";
import { useState } from "react";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Image from "next/image";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState({
    type: "password",
    icon: "ti ti-eye-off",
  });
  const router = useRouter();

  // const { pageStateHandler, userType } = props;
  //const [password, setPassword] = useState({ type: 'password', icon: 'fa-eye-slash' });
  const [signInError, setSignInError] = useState(null);
  //const router = useRouter();

  const handleClickShowPassword = () => {
    password.type === "password"
      ? setPassword({ ...password, type: "text", icon: "ti ti-eye" })
      : setPassword({ ...password, type: "password", icon: "ti ti-eye-off" });
  };

  // Yup schema to validate the form
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    password: Yup.string().required().min(4),
  });

  // Formik hook to handle the form state
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    // Pass the Yup schema to validate the form
    validationSchema,
    // Handle form submission
    onSubmit: async ({ email, password }) => {
      try {
        const res = await signIn("credentials", {
          email,
          password,
          //role: userType,
          redirect: false,
        });
        if (res?.error && !res?.ok) {
          if (res.error == "CredentialsSignin") {
            setSignInError("Something went wrong, please try again later!");
          } else {
            setSignInError(res.error);
          }
        } else {
          router.push({ pathname: "/", query: { credential: true } });
          //   if(res.status == 200 && res.ok){
          //   setTimeout(() => {
          //     location.reload();
          //   }, "1000");
          // }
        }
      } catch (error) {
        console.error("Authorization Error:", error);
        throw new Error("Authorization failed");
      }
    },
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <div>
      <div class="auth-main">
        <div class="auth-wrapper v2">
          <div class="auth-sidecontent">
            <Image
            height={564}
            width={505}
              src="/images/authentication/img-auth-sideimg.jpg"
              alt="images"
              class="img-fluid img-auth-side"
            />
          </div>
          <div class="auth-form">
            <div class="card my-md-5 my-3 px-3">
              <div class="card-body">
                <h4 class="text-center f-w-500 mb-3">Login with your email</h4>
                <form onSubmit={handleSubmit}>
                  <div class="mb-3">
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      id="floatingInput"
                      placeholder="Email Address"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email ? (
                      <span className="text-danger w-100">{errors.email}</span>
                    ) : null}
                  </div>
                  <div class="mb-3 position-relative form-group">
                    <div className="input-group">
                      <input
                        type={password.type}
                        name="password"
                        class="form-control"
                        id="floatingInput1"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <span className="input-group-text">
                        <i
                          className={`${password.icon}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickShowPassword()}
                        ></i>
                      </span>
                    </div>

                    {touched.password && errors.password ? (
                      <span className="text-danger d-block w-100">
                        {errors.password}
                      </span>
                    ) : null}
                    {<span className="text-danger">{signInError}</span>}
                  </div>
                  {/* <div class="d-flex mt-1 justify-content-between align-items-center">
                  <div class="form-check">
                    <input
                      class="form-check-input input-primary"
                      type="checkbox"
                      id="customCheckc1"
                      checked=""
                    />{" "}
                    <label
                      class="form-check-label text-muted"
                      for="customCheckc1"
                    >
                      Remember me?
                    </label>
                  </div>
                  <h6 class="text-secondary f-w-400 mb-0">
                    <Link href="forgot-password-v2.html">Forgot Password?</Link>
                  </h6>
                </div> */}
                  <span
                    onClick={() => router.push("/auth/forgot-password")}
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password<i class="ti ti-arrow-right"></i>
                  </span>
                  <div class="d-grid mt-4">
                    <button type="submit" class="btn btn-primary">
                      Login
                    </button>
                  </div>
                  {/* <div class="d-flex justify-content-between align-items-end mt-4">
                  <h6 class="f-w-500 mb-0">Don't have an Account?</h6>
                  <Link href="register-v2.html" class="link-primary">
                    Create Account
                  </Link>
                </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

Login.getLayout = function getLayout(page) {
  return <AuthLayout>{page}</AuthLayout>;
};
