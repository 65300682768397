import { api } from "@/utils/axiosConfigs";
import { defineCancelApiObject } from "@/utils/axiosUtils";
import { ENDPOINTS } from "@/utils/endpoints";

export const userServices = {
    
    userProfile: async function (params, cancel = false) {
        const response = await api.request({
          url: `${ENDPOINTS.USER_PROFILE}`,
          method: "GET",
          // retrieving the signal value by using the property name
          signal: cancel
            ? cancelApiObject[this.userProfile].handleRequestCancellation()
              .signal
            : undefined,
        });
    
        // returning the data
        return response.data;
      },

      updateUser: async function (params, cancel = false) {
        const response = await api.request({
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${ENDPOINTS.UPDATE_USER}/${params.id}`,
          method: "POST",
          data: params.data,
          // retrieving the signal value by using the property name
          signal: cancel
            ? cancelApiObject[this.updateUser].handleRequestCancellation()
              .signal
            : undefined,
        });
    
        // returning the data
        return response.data;
      },

      updateSettings: async function (params, cancel = false) {
        const response = await api.request({
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${ENDPOINTS.GET_UPDATE_GENERAL_SETTINGS}`,
          method: "POST",
          data: params,
          // retrieving the signal value by using the property name
          signal: cancel
            ? cancelApiObject[this.updateUser].handleRequestCancellation()
              .signal
            : undefined,
        });
    
        // returning the data
        return response.data;
      },

      getSettings: async function (params, cancel = false) {
        const response = await api.request({
          url: `${ENDPOINTS.GET_UPDATE_GENERAL_SETTINGS}`,
          method: "GET",
          // retrieving the signal value by using the property name
          signal: cancel
            ? cancelApiObject[this.updateUser].handleRequestCancellation()
              .signal
            : undefined,
        });
    
        // returning the data
        return response.data;
      },

      getRevenue: async function (params, cancel = false) {
        const response = await api.request({
          url: `${ENDPOINTS.GET_REVENUE}`,
          method: "GET",
          // retrieving the signal value by using the property name
          signal: cancel
            ? cancelApiObject[this.getRevenue].handleRequestCancellation()
              .signal
            : undefined,
        });
    
        // returning the data
        return response.data;
      },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(userServices);