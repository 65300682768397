import { api } from "@/utils/axiosConfigs";
import { defineCancelApiObject } from "@/utils/axiosUtils";
import { ENDPOINTS } from "@/utils/endpoints";

export const patientServices = {
  addPatient: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `${ENDPOINTS.ADD_PATIENT}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addPatient].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  editPatient: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.EDIT_PATIENT}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.editPatient].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updatePatient: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `${ENDPOINTS.UPDATE_PATIENT}`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updatePatient].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  patientDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PATIENT_DELETE}/${params}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.patientDelete].handleRequestCancellation()
          .signal
        : undefined,
    });
  
    // returning the data
    return response.data;
  },
  
  getPatientDetail: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `${ENDPOINTS.GET_PATIENT_DETAIL}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getPatientDetail].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updatePatientPassword: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.UPDATE_PATIENT_PASSWORD}`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updatePatientPassword].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  patientDetailUpdateSubmit: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `${ENDPOINTS.PATIENT_DETAIL_UPDATE_SUBMIT}`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.patientDetailUpdateSubmit].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  pdfSendPdf: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `${ENDPOINTS.SEND_PDF_FILE}`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.pdfSendPdf].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  patientMobileSearch: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PATIENT_MOBILE_SEARCH}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.patientMobileSearch].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  allpatientList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.FULL_PATIENT_LIST}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.allpatientList].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  prescriptionAppointmentList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PATIENT_APPOINTMENT_LIST}/${params.id}?page=${params.page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.prescriptionAppointmentList].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  prescriptionBillList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PATIENT_BILL_LIST}/${params.id}?page=${params.page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.prescriptionBillList].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  patientPrescriptionList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PATIENT_PRESCRIPTION_LIST}/${params.id}?page=${params.page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.patientPrescriptionList].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  patientDocumentList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PATIENT_DOCUMENT_LIST}/${params.id}?page=${params.page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.patientDocumentList].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  cityList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.CITY_LIST}/`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.cityList].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(patientServices);
