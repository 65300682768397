import { useEffect } from "react";

export const useLayoutChange = () => {
  const main_layout_change = (e) => {
    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("data-pc-layout", e);

    const offcanvas = document.querySelector(".pct-offcanvas");
    if (offcanvas) {
      const activeLink = document.querySelector(
        ".theme-main-layout > a.active"
      );
      if (activeLink) {
        activeLink.classList.remove("active");
      }

      const newActiveLink = document.querySelector(
        `.theme-main-layout > a[data-value='${e}']`
      );
      if (newActiveLink) {
        newActiveLink.classList.add("active");
      }
    }
  };

  useEffect(() => {
    const setLayout = () => {
      let layout = localStorage.getItem("layout");
      main_layout_change(layout);
      if (layout !== null && layout !== "") {
        switch (layout) {
          case "horizontal":
            document.querySelector(".pc-sidebar").classList.add("d-none");
            break;
          case "color-header":
            const logo = document.querySelector(
              ".pc-sidebar .m-header .logo-lg"
            );
            if (logo) {
              logo.setAttribute("src", "assets/images/ginnidevi-logo-sm.webp");
            }
            break;
          case "compact":
            break;
          case "tab":
            break;
          default:
            main_layout_change("vertical");
            localStorage.setItem("layout", "vertical");
        }
      }
    };

    setLayout();
  }, []);
};
