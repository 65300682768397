import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";

const Footer = () => {
  const { pathname } = useRouter();
  return (
    <>
      {/* <footer className="pc-footer">
        <div className="footer-wrapper container-fluid">
          <div className="row">
            <div className="col my-1">
              <p className="m-0">
                Appointment Management System &#9829; Created by Team{" "}
                <Link href="#" target="_blank">
                  KadamTech
                </Link>
              </p>
            </div>
            <div className="col-md-auto my-1">
              <ul className="list-inline footer-link mb-0">
                <li className="list-inline-item">
                  <Link href="/">Home</Link>
                </li>
                <li className="list-inline-item">
                  <Link href="#" target="_blank">
                    Terms and Conditions
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link href="#" target="_blank">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer> */}
      <div className="mobile-footer">
        <ul>
          <li>
            <Link href="/" className={pathname == "/" ? "active" : ""}>
              <i class="ti ti-home"></i>
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link
              href="/appointment/list"
              className={pathname == "/appointment/list" ? "active" : ""}
            >
              <i class="ti ti-list-check"></i>
              <span>Appointment</span>
            </Link>
          </li>
          <li>
            <Link
              href="/prescription/list"
              className={pathname == "/prescription/list" ? "active" : ""}
            >
              <i class="ti ti-file"></i>
              <span>Prescription</span>
            </Link>
          </li>
          <li>
            <Link
              href="/bill/list"
              className={pathname == "/bill/list" ? "active" : ""}
            >
              <i class="ti ti-file-invoice"></i>
              <span>Bill</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
