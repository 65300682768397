import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { doctorServices } from "../../../services/doctroServices";
// import { HYDRATE } from "next-redux-wrapper";

//doctor values
const addDoctor = {
    title: "Mr.",
    name: "",
    email: "",
    password: "",
    mobile: "",
    phone: "",
    address: "",
    designation: "",
    fees:"",
    specialization: "",
    short_biography: "",
    gender: 1,
    blood_group: "",
    status: 1,
    clinic_name: "",
    clinic_timings: "",
    clinic_address: "",
    clinic_website: "",
    clinic_tagline: "",
  };  

  //update doctor values
const updateDoctorValues = {
    id: "",
    name: "",
    email: "",
    password: "",
    mobile: "",
    phone: "",
    address: "",
    designation: "",
    fees:"",
    specialization: "",
    short_biography: "",
    gender: "1",
    blood_group: "",
    status: 1,
    clinic_name: "",
    clinic_timings: "",
    clinic_address: "",
    clinic_website: "",
    clinic_tagline: "",
  };

const initialState = {
    doctorValues:addDoctor,
    updateDoctor:updateDoctorValues,
    doctorShortBiography:"",

};

export const userSlice = createSlice({
    name: 'addDoctor',
    initialState,
    reducers: {
         setDoctorValues: (state, action) => {
             state.doctorValues = action.payload;
         },
         setUpdateDoctor: (state, action) => {
            state.updateDoctor = action.payload;
        },
        setdoctorShortBiography: (state, action) => {
            state.doctorShortBiography = action.payload;
        },
        // setDoctorList: (state, action) => {
        //     state.doctorList = action.payload;
        // },
        // setStatus: (state, action) => {
        //     state.status = action.payload;
        // }
    },
});

export const { setDoctorValues, setUpdateDoctor, setdoctorShortBiography } = userSlice.actions;
export default userSlice.reducer;

// //Thunks

// export const fetchDoctorList = createAsyncThunk("doctors/fetch", async () =>{
//     const data = await doctorServices.addDoctor();
//     //const data = await res.json();
// return data;
// });

