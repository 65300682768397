import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import styles from "../../styles/PageLoader.module.css";
import Image from "next/image";

const ApiLoader = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  return (
        <div className={styles.loader}>
            <Image width={151} height={159} src="/images/ginnidevi-logo-sm1.webp" alt="Ginni Devi Logo" />
        </div>
  );
};

export default ApiLoader;
