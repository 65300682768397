export const ENDPOINTS = {
  //authentication
  LOGIN: "api/login",
  FORGET_PASSWORD: "/forget-password",
  LOGOUT: "api/logout",
  EMAIL_VERIFY:"api/email/verify",

  //user
  USER_PROFILE: "api/user",
  UPDATE_USER: "api/update-profile",
 
  // dashboard

  GET_DOCTORS: "/api/list-doctor",
  GET_PATIENTS: "/api/list-patient",
  GET_APPOINTMENT: "/api/list-appointment",
  DOCTOR_DELETE: "/api/delete-doctor",
  PATIENT_DELETE: "/api/delete-patient",
  APPOINTMENT_DELETE: "/api/delete-appintment",
  SEND_VERIFICATION_LINK: "api/email/resend",

  //addDoctors
ADD_DOCTOR: "api/add-doctor",
UPDATE_DOCTOR: "api/update-doctor",
EDIT_DOCTOR_ID: "api/edit-doctor",

//doctor profile
GET_DOCTOR_DETAILS: "api/doctor-details",
DOCTOR_UPDATE_PASSWORD_SUBMIT: "api/update-doctor-password",
UPDATE_DOCTOR_DETAIL: "api/update-doctor-details",
GET_DOCTORS_SLOT: "api/get-doctor-slot",
UPDATE_DOCTOR_SETTINGS: "api/doctor-setting",
DOCTOR_EDUCATION_STORE: "api/doctor/education/store",
DOCTOR_EDUCATION_LIST: "api/doctor/education/list",
DOCTOR_EDUCATION_EDIT: "api/doctor/education/edit",
DOCTOR_EDUCATION_UPDATE: "api/doctor/education/update",
DOCTOR_EDUCATION_DELETE: "api/doctor/education/delete",
DOCTOR_EXPERIENCE_STORE: "api/doctor/experiences/store",
DOCTOR_EXPERIENCE_LIST: "api/doctor/experiences/list",
DOCTOR_EXPERIENCE_EDIT: "api/doctor/experiences/edit",
DOCTOR_EXPERIENCE_UPDATE: "api/doctor/experiences/update",
DOCTOR_EXPERIENCE_DELETE: "api/doctor/experiences/delete",
DOCTOR_APPOINTMENT_LIST: "api/doctor/appointment/list",
DOCTOR_CLINIC_STORE: "api/doctor/clinic/store",
DOCTOR_CLINIC_LIST: "api/doctor/clinic/list",
DOCTOR_CLINIC_EDIT: "api/doctor/clinic/edit",
DOCTOR_CLINIC_UPDATE: "api/doctor/clinic/update",
DOCTOR_CLINIC_DELETE: "api/doctor/clinic/delete",
DOCTOR_HOSPITAL_STORE: "api/doctor/hospital/store",
DOCTOR_HOSPITAL_LIST: "api/doctor/hospital/list",
DOCTOR_HOSPITAL_EDIT: "api/doctor/hospital/edit",
DOCTOR_HOSPITAL_UPDATE: "api/doctor/hospital/update",
DOCTOR_HOSPITAL_DELETE: "api/doctor/hospital/delete",

//patient
ADD_PATIENT: "api/add-patient",
EDIT_PATIENT: "api/edit-patient",
UPDATE_PATIENT: "api/update-patient",
PATIENT_MOBILE_SEARCH: "api/edit-Search",
PATIENT_DELETE: "api/delete-patient",

//Patient Profile
GET_PATIENT_DETAIL: "api/get-patient-detail",
UPDATE_PATIENT_PASSWORD: "api/update-patient-password",
PATIENT_DETAIL_UPDATE_SUBMIT: "api/update-patient",
PATIENT_APPOINTMENT_LIST: "api/patient/appointment/history",
PATIENT_BILL_LIST: "api/patient/bill/history",
PATIENT_PRESCRIPTION_LIST: "api/patient/prescription/history",
PATIENT_DOCUMENT_LIST: "api/patient/document/history",
CITY_LIST: "api/city",
FULL_PATIENT_LIST: "api/select-patient",
SEND_PDF_FILE: "api/save-download",

//Role
ROLE_LISTING: "api/list-role",
ADD_ROLE: "api/add-role",
DELETE_ROLE: "api/delete-role",
GET_PERMISSIONS: "api/get-permissions",
EDIT_ROLE: "api/edit-role",
UPDATE_ROLE: "api/update-role",
FULL_ROLE_LISTING: "api/get-roles",

//speciality
SPECIALITY_LISTING: "api/list-speciality",
GET_SPECIALITY_LISTING: "api/all-speciality",
ADD_SPECIALITY: "api/add-speciality",
DELETE_SPECIALITY: "api/delete-speciality",
UPDATE_SPECIALITY: "api/update-speciality",

//schedule
SCHEDULE_LISTING: "api/list-schedule",
DELETE_SCHEDULE: "api/delete-schedule",
ADD_SCHEDULE: "api/add-schedule",
UPDATE_SCHEDULE: "api/update-schedule",
EDIT_SCHEDULE: "api/edit-schedule",

//staff
STAFF_LISTING: "api/list-staff",
DELETE_STAFF: "api/delete-staff",
ADD_STAFF: "api/add-staff",
UPDATE_STAFF: "api/update-staff",
EDIT_STAFF: "api/edit-staff",

//document
DOCUMENT_LISTING: "api/list-document",
DELETE_DOCUMENT: "api/delete-document",
ADD_DOCUMENT: "api/add-document",
UPDATE_DOCUMENT: "api/update-document",
EDIT_DOCUMENT: "api/edit-document",

//appointment
DELETE_APPOINTMENT: "api/delete-appointment",
ADD_APPOINTMENT: "api/add-appointment",
EDIT_APPOINTMENT: "api/edit-appointment",
UPDATE_APPOINTMENT: "api/update-appointment",
DOWNLOAD_APPOINTMENT: "api/download-invoice",
UPDATE_APPOINTMENT_STATUS: "api/appointment/status/update",
GET_APPOINTMENT_DETAIL: "api/appointment-details",

//bed
BED_LISTING: "api/list-bed",
DELETE_BED: "api/delete-bed",
EDIT_BED: "api/edit-bed",
UPDATE_BED: "api/update-bed",
ADD_BED: "api/add-bed",

//bill
BILL_LISTING: "api/list-bill",
DELETE_BILL: "api/delete-bill",
EDIT_BILL: "api/edit-bill",
GET_BILL: "api/bill-details",
UPDATE_BILL: "api/update-bill",
ADD_BILL: "api/add-bill",

//LEAD
GET_LEADS: "api/leads",
ADD_LEAD: "api/lead/store",
DELETE_LEAD: "api/lead/delete",
UPDATE_LEAD: "api/lead/update",
UPDATE_ASSIGN_LEAD_STATUS: "api/lead/assign/update",
UPDATE_LEAD_STATUS: "api/lead/status/update",
EDIT_LEAD: "api/lead/edit",
GET_LEAD_CREATE_DATA: "api/lead/create",
GET_STATE: "api/get-states",
GET_CITY: "api/get-cities",
ADD_LEAD_SERVICE: "api/lead-service-type",
ADD_LEAD_SOURCE: "api/lead-source",
CAMPAIGN_AUTOCOMPLETE: "api/autocomplete",
GET_LEAD_DATA: "api/lead/show",
ADD_UPDATE_LEAD_NOTES: "api/lead-notes",
LEAD_NOTES_LIST: "api/lead/notes/list",
DELETE_LEAD_NOTE: "api/lead-notes/delete",
ADD_UPDATE_LEAD_ATTACHMENT: "api/lead-attach",
LEAD_ATTACHMENTS_LIST: "api/lead/attachments/list",
DELETE_LEAD_ATTACHMENT: "api/lead-attachment/delete",
ADD_UPDATE_LEAD_FOLLOWUP: "api/folowUpSubmit",
LEAD_FOLLOWUP_LIST: "api/getFollowUp",
DELETE_LEAD_FOLLOWUP: "api/destroyFollowUp",
CONVERT_TO_CUSTOMER: "api/lead/patient/convert",

//SERVICE
SERVICE_LISTING: "api/list-service",
DELETE_SERVICE: "api/delete-service",
EDIT_SERVICE: "api/edit-service",
UPDATE_SERVICE: "api/update-service",
ADD_SERVICE: "api/add-service",
SERVICE_LISTING_WOP: "api/all-service",

//assignBed
ASSIGN_BED_LISTING: "api/assign-listing",
ASSIGN_DELETE_BED: "api/delete-bedassign",
ASSIGN_EDIT_BED: "api/edit-bedassign",
ASSIGN_UPDATE_BED: "api/update-bedassign",
ASSIGN_ADD_BED: "api/add-bedassign",

//notice
NOTICE_LISTING: "api/list-notice",
DELETE_NOTICE: "api/delete-notice",
UPDATE_NOTICE: "api/update-notice",
ADD_NOTICE: "api/add-notice",

// prescription
CREATE_PRESCRIPTION: "api/prescription/store",
PRESCRIPTION_LIST: "api/prescription",
PRESCRIPTION_SHOW: "api/prescription/show",
PRESCRIPTION_EDIT: "api/prescription/edit",
PRESCRIPTION_UPDATE: "api/prescription/update",
PRESCRIPTION_DELETE: "api/prescription/delete",
FETCH_HISTORY: "api/prescription/investigation",
FETCH_SYMPTOMS: "api/prescription/symptoms",
FETCH_PRESCRIBED_TEST: "api/prescription/tests",
FETCH_DRUGS: "api/prescription/create",
FETCH_MEDICINE: "api/medicines",
GET_PATIENT: "api/prescription/search",
FETCH_MEDICINE_DETAILS: "api/medicines/quantity_unit",
REFERRED_BY_SEARCH: "api/referred-search",
MEDICINE_NAME_SEARCH: "api/medicine-search",
PRESCRIPTION_MAIL: "api/prescription/send/mail",

//medicine
MEDICINE_LISTING: "api/medicines",
DELETE_MEDICINE: "api/medicines/delete",
EDIT_MEDICINE: "api/medicines/edit",
UPDATE_MEDICINE: "api/medicines/update",
ADD_MEDICINE: "api/medicines/store",
MEDICINE_LISTING_WOP: "api/all-medicine",

//calendar
PATIENT_SEARCH: "api/search-patient",
DOCTOR_SCHEDULE: "api/doctor_schedule",
GET_DOCTORS_LIST: "api/getDoctors",
TODAY_APPOINTMENT: "api/todayAppointments",
CALENDAR_APPOINTMENT: "api/calendar-appointment",
DOCTOR_APPOINTMENT: "api/doctorAppointments",
UPDATE_TODAY_APPOINTMENT: "api/todayAppointments",
CANCEL_APPOINTMENT: "api/cancelAppointment",
APPOINTMENT_STATUS: "api/appointment-status",

//layouts
LAYOUT_STORE: "api/layouts/store",

//settings
GET_UPDATE_GENERAL_SETTINGS: "api/settings",
GET_REVENUE: "api/dashboard",

//speciality
GET_SPECIALITY_SERVICES: "api/specialityServices",

//activity log
GET_ACTIVITY_LOG: "api/activity/log/list",

};

