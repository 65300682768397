import { api } from "@/utils/axiosConfigs";
import { defineCancelApiObject } from "@/utils/axiosUtils";
import { ENDPOINTS } from "@/utils/endpoints";

export const doctorServices = {
  getAdminDetails: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.USER_PROFILE}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getAdminDetails].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateDoctorSettings: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.UPDATE_DOCTOR_SETTINGS}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateDoctorSettings].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  sendVerificationLink: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.SEND_VERIFICATION_LINK}/${params}`,
      method: "GET",
      //data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.sendVerificationLink].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorsList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_DOCTORS}?length=${
        params.length ? params.length : 10
      }&search=${params.search ? params.search : ""}&field=${
        params.field ? params.field : ""
      }&sortOrder=${params.sortOrder ? params.sortOrder : ""}&daterange=${
        params.daterange ? params.daterange : ""
      }&page=${params.page ? params.page : 1}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getDoctorsList].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctors: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_DOCTORS_LIST}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getDoctors].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_DELETE}/${params}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.doctorDelete].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getPatientsList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_PATIENTS}?length=${
        params.length ? params.length : 10
      }&search=${params.search ? params.search : ""}&field=${
        params.field ? params.field : ""
      }&sortOrder=${params.sortOrder ? params.sortOrder : ""}&daterange=${
        params.daterange ? params.daterange : ""
      }&page=${params.page ? params.page : 1}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getPatientsList].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  patientDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PATIENT_DELETE}/${params}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.patientDelete].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getAppointmentsList: async function (params, cancel = false) {
    console.log(params,"params")
    const response = await api.request({
      url: `${ENDPOINTS.GET_APPOINTMENT}?length=${
        params.length ? params.length : 10
      }&search=${params.search ? params.search : ""}&field=${
        params.field ? params.field : ""
      }&sortOrder=${params.sortOrder ? params.sortOrder : ""}&daterange=${
        params.daterange ? params.daterange : ""
      }&page=${params.page ? params.page : 1}&status=${(params.status && params.status !== null) ||  (params.status && params.status !== undefined) ? params.status : ""}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getAppointmentsList].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  appointmentDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.APPOINTMENT_DELETE}/${params}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.appointmentDelete].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  addDoctor: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.ADD_DOCTOR}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addDoctor].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateDoctor: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.UPDATE_DOCTOR}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateDoctor].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateDoctor: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.UPDATE_DOCTOR}/${params.updateId}`,
      method: "POST",
      data: params.fData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateDoctor].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  editDoctorId: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.EDIT_DOCTOR_ID}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.editDoctorId].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  specialityServices: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_SPECIALITY_SERVICES}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.specialityServices].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_DOCTOR_DETAILS}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getDoctorDetail].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorUpdatePasswordSubmit: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_UPDATE_PASSWORD_SUBMIT}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorUpdatePasswordSubmit
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateDoctorDetail: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.UPDATE_DOCTOR_DETAIL}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateDoctorDetail].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorSlot: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_DOCTORS_SLOT}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getDoctorSlot].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorLayoutStore: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.LAYOUT_STORE}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.doctorLayoutStore].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorEducationStore: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EDUCATION_STORE}/${params.get("id")}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.doctorEducationStore].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorEducationList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EDUCATION_LIST}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getDoctorEducationList].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorEducationUpdate: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EDUCATION_UPDATE}/${params.get("id")}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorEducationUpdate
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorEducationEdit: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EDUCATION_EDIT}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorEducationEdit
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  doctorAppointmentList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_APPOINTMENT_LIST}/${params.id}?page=${params.page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorAppointmentList
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorEducationEdit: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EDUCATION_EDIT}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorEducationEdit
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorEducationDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EDUCATION_DELETE}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorEducationDelete
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorExperienceStore: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EXPERIENCE_STORE}/${params.get("doctor_id")}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorExperienceStore
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorExperienceUpdate: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EXPERIENCE_UPDATE}/${params.get("doctor_id")}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorExperienceUpdate
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorExperienceList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EXPERIENCE_LIST}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorExperienceList
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorExperienceEdit: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EXPERIENCE_EDIT}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorExperienceEdit
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorExperienceDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_EXPERIENCE_DELETE}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorExperienceDelete
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorClinicStore: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.DOCTOR_CLINIC_STORE}/${params.get("id")}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorClinicStore
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorClinicUpdate: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.DOCTOR_CLINIC_UPDATE}/${params.get("clinic_id")}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorClinicUpdate
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorClinicList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_CLINIC_LIST}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorClinicList
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorClinicEdit: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_CLINIC_EDIT}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorClinicEdit
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorClinicDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_CLINIC_DELETE}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorClinicDelete
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorHospitalStore: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.DOCTOR_HOSPITAL_STORE}/${params.get("id")}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorHospitalStore
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  doctorHospitalUpdate: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.DOCTOR_HOSPITAL_UPDATE}/${params.get("hospital_id")}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.doctorHospitalUpdate
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorHospitalList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_HOSPITAL_LIST}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorHospitalList
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorHospitalEdit: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_HOSPITAL_EDIT}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorHospitalEdit
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getDoctorHospitalDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DOCTOR_HOSPITAL_DELETE}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
            this.getDoctorHospitalDelete
          ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(doctorServices);
